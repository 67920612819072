dmx.Component('masonry', {

    extends: 'repeat',

    constructor: function(node, parent) {
        this.onResize = this.onResize.bind(this);
        this.reflow = dmx.debounce(this.reflow.bind(this));
        this.breakpoints = {
            sm: 480,
            md: 768,
            lg: 992,
            xl: 1200
        };
        dmx.BaseComponent.call(this, node, parent);
    },

    attributes: {
        columns: {
            // the number of columns to create
            type: Number,
            default: 4
        },

        'columns-sm': { // >= 480px
            type: Number,
            default: null
        },

        'columns-md': { // >= 768px
            type: Number,
            default: null
        },

        'columns-lg': { // >= 992px
            type: Number,
            default: null
        },

        'columns-xl': { // >= 1200px
            type: Number,
            default: null
        },

        gutter: {
            // the gutter size in px
            type: Number,
            default: 15
        },

        'gutter-sm': { // >= 480px
            type: Number,
            default: null
        },

        'gutter-md': { // >= 768px
            type: Number,
            default: null
        },

        'gutter-lg': { // >= 992px
            type: Number,
            default: null
        },

        'gutter-xl': { // >= 1200px
            type: Number,
            default: null
        },

        'preserve-order': {
            // will order the items from left to right into the columns
            // when false it will optimize layout by equalizing the height of each column
            type: Boolean,
            default: false
        },

        animated: {
            type: Boolean,
            default: false
        },

        'animation-duration': {
            type: Number,
            default: 400
        }
    },

    methods: {
        reflow: function() {
            // allow manual reflow (when user changes size of item outside of app connect)
            this.reflow();
        }
    },

    render: function(node) {
        dmx.Component('repeat').prototype.render.call(this, node);
        // container must have position relative
        this.$node.style.setProperty('position', 'relative');
        // listen to resize event
        window.addEventListener('resize', this.onResize);
    },

    updated: function() {
        this.reflow();
    },

    onResize: function(event) {
        if (!event.dmxMasonry) {
            this.reflow();
        }
    },

    reflow: function() {
        if (!this.children.length) return;
        if (this.insideReflow) return;

        dmx.array(this.$node.querySelectorAll('img')).forEach(function(img) {
            if (!img.dmxMasonry) {
                // attach load event listener to images to update layout
                img.addEventListener('load', this.reflow.bind(this));
                if (img.src) {
                    img.src = img.src;
                }
                img.dmxMasonry = true;
            }
        }, this);

        var viewportWidth = window.innerWidth;
        var columns = this.props.columns;
        var gutter = this.props.gutter;

        ['sm','md','lg','xl'].forEach(function(breakpoint) {
            if (viewportWidth >= this.breakpoints[breakpoint]) {
                columns = this.props['columns-' + breakpoint] || columns;
                gutter = this.props['gutter-' + breakpoint] || gutter;
            }
        }, this);

        // get all nodes that must be updated
        var nodes = dmx.array(this.$node.childNodes).filter(function(node) { return node.nodeType === 1; });

        // calculate the column width
        var style = window.getComputedStyle(this.$node);
        var padding = {
            left: parseFloat(style.paddingLeft),
            right: parseFloat(style.paddingRight)
        };
        var columnWidth = Math.floor((this.$node.clientWidth - padding.left - padding.right - ((columns - 1) * gutter)) / columns);
        // set the width on all nodes
        nodes.forEach(function(node) {
            // force border-box
            node.style.setProperty('box-sizing', 'border-box');
            node.style.setProperty('width', columnWidth + 'px');
        }, this);
        // trigger resize now that we have width
        try {
            var event = new Event('resize');
            event.dmxMasonry = true;
            window.dispatchEvent(event);
        } catch (err) {
            var event = document.createEvent('Event');
            event.dmxMasonry = true;
            event.initEvent('resize', false, false);
            window.dispatchEvent(event);
        }
        // prepare an array for the column heights
        var columnHeights = Array.apply(null, Array(columns)).map(function() { return 0; });
        // get all the nodes heights
        var nodesHeights = nodes.map(function(node) { return node.clientHeight; });

        nodes.forEach(function(node, index) {
            // which column should we place the item
            var columnIndex = this.props['preserve-order'] ? index % columns : columnHeights.indexOf(Math.min.apply(Math, columnHeights));
            // calculate x position
            var x = (columnIndex * columnWidth) + (columnIndex * gutter);
            // calculate y position
            var y = columnHeights[columnIndex];

            // position the node
            node.style.setProperty('transform', 'translate3d(' + x + 'px, ' + y + 'px, 0px)');

            if (nodesHeights[index]) {
                // only execute following code on first run
                if (!node.dmxMasonryInit) {
                    // set position absolute
                    node.style.setProperty('position', 'absolute');
                    if (this.props.animated) {
                        node.style.setProperty('transition', 'transform ' + this.props['animation-duration'] + 'ms');
                    }
                    // we make it visible on the next animation frame to prevent some flickering
                    window.requestAnimationFrame(function() {
                        node.style.setProperty('visibility', 'visible');
                    });
                    // we did init this node, so not execute this code next reflow
                    node.dmxMasonryInit = true;
                }

                // update the columns heights array
                columnHeights[columnIndex] += nodesHeights[index] + gutter;
            }
        }, this);

        this.$node.style.setProperty('height', (Math.max.apply(Math, columnHeights) - gutter) + 'px');
    }

});
